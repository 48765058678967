<template>
  <div id="reports">
    <div class="page-title px-4 mb-4">
      <h2>
        <span class="me-2">Reports</span>
      </h2>
    </div>

    <div>
      <v-row class="mt-1 mb-3" style="align-items: center">
        <v-col cols="5">
          <v-text-field id="startDate" v-model="startDate" label="Start date" outlined hide-details dense type="date" />
        </v-col>
        <v-col cols="5">
          <v-text-field id="endDate" v-model="endDate" label="End date" outlined hide-details dense type="date" />
        </v-col>
        <v-col cols="2">
          <v-btn color="primary" style="min-width: 100%" @click="fetchData">Go</v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="loading" class="fill-height d-flex justify-center align-center">
      <Loader />
    </div>

    <div v-else>
      <v-tabs v-model="currentTab" show-arrows>
        <v-tab v-for="tab in tabs" :key="`${tab.title}-${tab.icon}`">
          <v-icon class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>
            {{ tab.title }}
          </span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab" style="background-color: transparent !important">
        <!-- OFF ROUTES -->
        <v-tab-item>
          <div v-if="offRoutes.length">
            <div class="mb-4">
              <v-row>
                <v-col cols="12" md="4">
                  <v-card class="">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <p class="text-center text-no-wrap pt-1 text--xs">Total Off Routes</p>
                        <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                          <div>
                            {{ offRoutesSummary.totalOffRoutes }}
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4">
                  <v-card class="">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <p class="text-center text-no-wrap pt-1 text--xs">Number of Vehicles</p>
                        <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                          <div>
                            {{ offRoutesSummary.totalVehicles }}
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4">
                  <v-card class="">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <p class="text-center text-no-wrap pt-1 text--xs">Number of Trips</p>
                        <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                          <div>
                            {{ offRoutesSummary.totalTrips }}
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </div>

            <v-card>
              <div class="px-3">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="offRoutesGroupBy"
                      :items="offRoutesGroupByItems"
                      item-text="text"
                      item-value="value"
                      label="Group by"
                      placeholder="Group by"
                      outlined
                      hide-details
                      clearable
                      multiple
                      dense
                      class="user-search me-3"
                    />
                  </v-col>

                  <v-spacer></v-spacer>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="offroutesSearch"
                      append-icon=""
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>

              <div>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="[
                        {
                          text: 'Vehicle ID',
                          value: 'vehicle_id',
                          sortable: true,
                        },
                        {
                          text: 'Trip ID',
                          value: 'trip_id',
                          sortable: true,
                        },
                        {
                          text: 'Date',
                          value: 'date',
                          sortable: true,
                          filterable: false,
                        },
                        {
                          text: 'Total',
                          value: 'total_offroutes',
                          sortable: true,
                          filterable: false,
                        },
                      ]"
                      :items="offRoutesData"
                      :custom-sort="customSort"
                      :search="offroutesSearch"
                      :sort-by.sync="offroutesSortBy"
                      :sort-desc.sync="offroutesSortDesc"
                      :must-sort="true"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            <router-link
                              :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                              class="cursor-pointer text-decoration-none font-weight-semibold text--primary"
                              >{{ item.vehicle_id }}
                            </router-link>
                          </td>
                          <td>{{ item.trip_id }}</td>
                          <td>{{ item.date }}</td>
                          <td>{{ item.total_offroutes }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>

          <div v-else>
            <span class="font-weight-semibold text--primary"> No off routes data available </span>
          </div>
        </v-tab-item>
        <!-- END OFF ROUTES -->

        <!-- TRIP ASSIGNMENTS -->
        <v-tab-item>
          <div v-if="trips.length">
            <div class="mb-4">
              <v-row>
                <v-col cols="12" md="4">
                  <v-card class="">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <p class="text-center text-no-wrap pt-1 text--xs">Total Trips Assigned</p>
                        <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                          <div>
                            {{ tripsSummary.totalTripsAssigned }}
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4">
                  <v-card class="">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <p class="text-center text-no-wrap pt-1 text--xs">Total Trips Confirmed</p>
                        <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                          <div>
                            {{ tripsSummary.totalTripsConfirmed }}
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4">
                  <v-card class="">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <p class="text-center text-no-wrap pt-1 text--xs">Total Mismatched Trips</p>
                        <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                          <div>
                            {{ tripsSummary.totalMismatched }}
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </div>

            <v-card>
              <div class="px-3">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="tripAssignmentsGroupBy"
                      :items="tripAssignmentsGroupByItems"
                      item-text="text"
                      item-value="value"
                      label="Group by"
                      placeholder="Group by"
                      outlined
                      hide-details
                      clearable
                      multiple
                      dense
                      class="user-search me-3"
                    />
                  </v-col>

                  <v-spacer></v-spacer>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="tripAssignmentsSearch"
                      append-icon=""
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>

              <div>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="[
                        {
                          text: 'Vehicle ID',
                          value: 'vehicle_id',
                          sortable: true,
                        },
                        {
                          text: 'Trips Assigned',
                          value: 'total_assigned',
                          sortable: true,
                          filterable: false,
                        },
                        {
                          text: 'Trips Confirmed',
                          value: 'total_confirmed',
                          sortable: true,
                          filterable: false,
                        },
                        {
                          text: 'Trips Mismatch',
                          value: 'total_mismatch',
                          sortable: true,
                          filterable: false,
                        },
                        {
                          text: 'Date',
                          value: 'date',
                          sortable: true,
                          filterable: false,
                        },
                      ]"
                      :items="tripsData"
                      :custom-sort="customSort"
                      :search="tripAssignmentsSearch"
                      :sort-by.sync="tripAssignmentsSortBy"
                      :sort-desc.sync="tripAssignmentsSortDesc"
                      :must-sort="true"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            <router-link
                              :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                              class="cursor-pointer text-decoration-none font-weight-semibold text--primary"
                              >{{ item.vehicle_id }}
                            </router-link>
                          </td>
                          <td>{{ item.total_assigned }}</td>
                          <td>{{ item.total_confirmed }}</td>
                          <td>{{ item.total_mismatch }}</td>
                          <td>{{ item.date }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>

          <div v-else>
            <span class="font-weight-semibold text--primary"> No trips data available </span>
          </div>
        </v-tab-item>
        <!-- END TRIP ASSIGNMENTS -->

        <!-- NETWORK USAGE -->
        <v-tab-item>
          <div v-if="networkUsage.length">
            <div class="mb-4">
              <v-row>
                <v-col cols="4">
                  <v-card class="">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <p class="text-center text-no-wrap pt-1 text--xs">Total Upload</p>
                        <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                          <div>
                            {{ networkUsageSummary.totalUpload }}
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="4">
                  <v-card class="">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <p class="text-center text-no-wrap pt-1 text--xs">Total Download</p>
                        <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                          <div>
                            {{ networkUsageSummary.totalDownload }}
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="4">
                  <v-card class="">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <p class="text-center text-no-wrap pt-1 text--xs">Total Usage</p>
                        <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                          <div>
                            {{ networkUsageSummary.totalUsage }}
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </div>

            <v-card>
              <div class="px-3">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="networkGroupBy"
                      :items="networkGroupByItems"
                      item-text="text"
                      item-value="value"
                      label="Group by"
                      placeholder="Group by"
                      outlined
                      hide-details
                      clearable
                      multiple
                      dense
                      class="user-search me-3"
                    />
                  </v-col>

                  <v-spacer></v-spacer>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="networkSearch"
                      append-icon=""
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>

              <div v-if="showNetworkColour()" class="px-3">
                <v-row>
                  <v-col cols="12">
                    <p class="text--primary font-weight-medium">
                      Highlighting devices with daily total usage over
                      <v-chip color="error">{{ networkUsageAlertMB }} MB</v-chip> and
                      <v-chip color="warning">{{ networkUsageWarningMB }} MB</v-chip>
                    </p>
                  </v-col>
                </v-row>
              </div>

              <div>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="[
                        {
                          text: 'Device ID',
                          value: 'device_id',
                          sortable: true,
                        },
                        {
                          text: 'Vehicle ID',
                          value: 'vehicle_id',
                          sortable: true,
                        },
                        {
                          text: 'Upload',
                          value: 'upload',
                          sortable: true,
                          filterable: false,
                        },
                        {
                          text: 'Download',
                          value: 'download',
                          sortable: true,
                          filterable: false,
                        },
                        {
                          text: 'Total Usage',
                          value: 'total_usage',
                          sortable: true,
                          filterable: false,
                        },
                        {
                          text: 'Date',
                          value: 'date',
                          sortable: true,
                          filterable: false,
                        },
                      ]"
                      :items="networkUsageData"
                      :custom-sort="customSort"
                      :search="networkSearch"
                      :sort-by.sync="networkSortBy"
                      :sort-desc.sync="networkSortDesc"
                      :must-sort="true"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            <router-link
                              :to="{ name: 'next-stop-device-config', params: { id: item.device_id } }"
                              class="cursor-pointer text-decoration-none font-weight-semibold text--primary"
                              >{{ item.device_id }}
                            </router-link>
                          </td>
                          <td>
                            <router-link
                              :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                              class="cursor-pointer text-decoration-none font-weight-semibold text--primary"
                              >{{ item.vehicle_id }}
                            </router-link>
                          </td>
                          <td>{{ item.upload }}</td>
                          <td>{{ item.download }}</td>
                          <td>
                            <v-chip :color="getNetworkColour(item.total_bytes)">{{ item.total_usage }}</v-chip>
                          </td>
                          <td>{{ item.date }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>

          <div v-else>
            <span class="font-weight-semibold text--primary"> No network usage data available </span>
          </div>
        </v-tab-item>
        <!-- END NETWORK USAGE -->

        <!-- TRIP REPORTS -->
        <v-tab-item>
          <div v-if="tripReports.length">
            <div class="mb-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-card class="">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <p class="text-center text-no-wrap pt-1 text--xs">Total Trip Reports</p>
                        <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                          <div>
                            {{ tripReportsSummary.totalTripReports }}
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" md="6">
                  <v-card class="">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <p class="text-center text-no-wrap pt-1 text--xs">Unique Vehicles</p>
                        <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                          <div>
                            {{ tripReportsSummary.totalVehicles }}
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </div>

            <v-card>
              <div class="px-3">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="tripReportsSearch"
                      append-icon=""
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="[
                        {
                          text: 'Vehicle ID',
                          value: 'vehicle_id',
                          sortable: true,
                        },
                        {
                          text: 'Trip ID',
                          value: 'trip_id',
                          sortable: true,
                        },
                        {
                          text: 'Trip Start Datetime',
                          value: 'start_datetime',
                          sortable: true,
                          filterable: false,
                        },
                        {
                          text: 'Trip End Datetime',
                          value: 'end_datetime',
                          sortable: true,
                          filterable: false,
                        },
                      ]"
                      :items="tripReportsData"
                      :custom-sort="customSort"
                      :sort-by.sync="tripReportsSortBy"
                      :sort-desc.sync="tripReportsSortDesc"
                      :must-sort="true"
                      :search="tripReportsSearch"
                    >
                      <template v-slot:item.vehicle_id="{ item }">
                        <tr>
                          <td>
                            <router-link
                              :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                              class="cursor-pointer text-decoration-none font-weight-semibold text--primary"
                              >{{ item.vehicle_id }}
                            </router-link>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>

          <div v-else>
            <span class="font-weight-semibold text--primary"> No trip reports data available </span>
          </div>
        </v-tab-item>
        <!-- END TRIP REPORTS -->
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mdiTimelineRemove, mdiWan, mdiBusClock, mdiTransitConnectionVariant } from "@mdi/js"
import byteSize from "byte-size"
import axios from "axios"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import Loader from "@/components/Loader.vue"
import store from "@/store"

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: "Reports",
  metaInfo: {
    title: "Reports",
  },
  components: {
    Loader,
  },
  data() {
    return {
      currentTab: "",
      tabs: [
        {
          title: "Off Routes",
          icon: mdiTimelineRemove,
        },
        {
          title: "Trip Assignments",
          icon: mdiBusClock,
        },
        {
          title: "Network usage",
          icon: mdiWan,
        },
        {
          title: "Trip Reports",
          icon: mdiTransitConnectionVariant,
        },
      ],
      startDate: dayjs.tz(new Date(), "Pacific/Auckland").subtract(1, "day").format("YYYY-MM-DD"),
      endDate: dayjs.tz(new Date(), "Pacific/Auckland").subtract(1, "day").format("YYYY-MM-DD"),
      loading: false,
      offRoutes: [],
      networkUsage: [],
      trips: [],
      tripReports: [],
      offRoutesGroupBy: null,
      offRoutesGroupByItems: [
        {
          text: "Vehicle",
          value: "vehicle_id",
        },
        {
          text: "Trip",
          value: "trip_id",
        },
        {
          text: "Date",
          value: "date",
        },
      ],
      tripAssignmentsGroupBy: null,
      tripAssignmentsGroupByItems: [
        {
          text: "Vehicle",
          value: "vehicle_id",
        },
        {
          text: "Date",
          value: "date",
        },
      ],
      networkGroupBy: null,
      networkGroupByItems: [
        {
          text: "Device",
          value: "device_id",
        },
        {
          text: "Date",
          value: "date",
        },
      ],
      offRoutesRequest: axios.CancelToken.source(),
      networkUsageRequest: axios.CancelToken.source(),
      tripsRequest: axios.CancelToken.source(),
      tripReportsRequest: axios.CancelToken.source(),
      networkUsageAlertMB: 300, // 300MB
      networkUsageAlert: 300 * 1000 * 1000, // 300MB
      networkUsageWarningMB: 200, // 200MB
      networkUsageWarning: 200 * 1000 * 1000, // 200MB
      networkSortBy: "total_usage",
      networkSortDesc: true, // true for descending order, false for ascending order
      networkSearch: "",
      offroutesSortBy: "total_offroutes",
      offroutesSortDesc: true, // true for descending order, false for ascending order
      offroutesSearch: "",
      tripAssignmentsSortBy: "total_mismatch",
      tripAssignmentsSortDesc: true,
      tripAssignmentsSearch: "",
      tripReportsSortBy: "start_datetime",
      tripReportsSortDesc: false,
      tripReportsSearch: "",
    }
  },
  computed: {
    offRoutesSummary() {
      if (!this.offRoutes.length) {
        return {
          totalOffRoutes: 0,
          totalVehicles: 0,
          totalTrips: 0,
        }
      }

      let total = 0
      const trips = []
      const vehicles = []

      this.offRoutes.forEach(item => {
        total += item.total

        if (!trips.includes(item.trip_id)) {
          trips.push(item.trip_id)
        }

        if (!vehicles.includes(item.vehicle_id)) {
          vehicles.push(item.vehicle_id)
        }
      })

      return {
        totalOffRoutes: total,
        totalVehicles: vehicles.length,
        totalTrips: trips.length,
      }
    },
    offRoutesData() {
      if (!this.offRoutes.length) {
        return []
      }

      const rows = []
      const hasGroupBy = this.offRoutesGroupBy && this.offRoutesGroupBy.length > 0

      this.offRoutes.forEach(item => {
        const formattedDate = dayjs.tz(item.date, "Pacific/Auckland").format("YYYY-MM-DD")

        let rowIndex

        if (hasGroupBy) {
          rowIndex = rows.findIndex(r =>
            this.offRoutesGroupBy.every(group => {
              if (group === "vehicle_id") {
                return r.vehicle_id === item.vehicle_id
              }
              if (group === "date") {
                return r.date === formattedDate
              }
              if (group === "trip_id") {
                return r.trip_id === item.trip_id
              }

              return false
            }),
          )
        } else {
          rowIndex = rows.findIndex(
            r => r.trip_id === item.trip_id && r.vehicle_id === item.vehicle_id && r.date === formattedDate,
          )
        }

        if (rowIndex === -1) {
          const row = {
            vehicle_id: !hasGroupBy || this.offRoutesGroupBy?.includes("vehicle_id") ? item.vehicle_id : "-",
            trip_id: !hasGroupBy || this.offRoutesGroupBy?.includes("trip_id") ? item.trip_id : "-",
            total_offroutes: item.total,
            date: !hasGroupBy || this.offRoutesGroupBy?.includes("date") ? formattedDate : "-",
          }

          rows.push(row)
        } else {
          rows[rowIndex].total_offroutes += item.total
        }
      })

      return rows
    },
    networkUsageSummary() {
      if (!this.networkUsage.length) {
        return {
          totalUpload: 0,
          totalDownload: 0,
          totalUsage: 0,
        }
      }

      let totalUpload = 0
      let totalDownload = 0

      this.networkUsage.forEach(item => {
        totalUpload += item.upload
        totalDownload += item.download
      })

      return {
        totalUpload: byteSize(totalUpload).toString(),
        totalDownload: byteSize(totalDownload).toString(),
        totalUsage: byteSize(totalDownload + totalUpload).toString(),
      }
    },
    networkUsageData() {
      if (!this.networkUsage.length) {
        return []
      }

      const rows = []
      const hasGroupBy = this.networkGroupBy && this.networkGroupBy.length > 0

      this.networkUsage.forEach(item => {
        const formattedDate = dayjs.tz(item.date, "Pacific/Auckland").format("YYYY-MM-DD")

        let rowIndex

        if (hasGroupBy) {
          rowIndex = rows.findIndex(r =>
            this.networkGroupBy.every(group => {
              if (group === "device_id") {
                return r.device_id === item.device_id
              }
              if (group === "date") {
                return r.date === formattedDate
              }

              return false
            }),
          )
        } else {
          rowIndex = rows.findIndex(r => r.device_id === item.device_id && r.date === formattedDate)
        }

        if (rowIndex === -1) {
          const row = {
            device_id: !hasGroupBy || this.networkGroupBy?.includes("device_id") ? item.device_id : "-",
            vehicle_id: !hasGroupBy || this.networkGroupBy?.includes("device_id") ? item.vehicle_id : "-",
            upload: item.upload,
            download: item.download,
            total: item.download + item.upload,
            date: !hasGroupBy || this.networkGroupBy?.includes("date") ? formattedDate : "-",
          }

          rows.push(row)
        } else {
          rows[rowIndex].upload += item.upload
          rows[rowIndex].download += item.download
          rows[rowIndex].total += item.upload + item.download
        }
      })

      // Add in byte size formatting
      const formattedRows = rows.map(item => ({
        device_id: item.device_id,
        vehicle_id: item.vehicle_id,
        upload: byteSize(item.upload).toString(),
        upload_bytes: item.upload,
        download: byteSize(item.download).toString(),
        download_bytes: item.download,
        total_usage: byteSize(item.total).toString(),
        total_bytes: item.total,
        date: item.date,
      }))

      return formattedRows
    },
    tripsSummary() {
      if (!this.trips.length) {
        return {
          totalTripsAssigned: 0,
          totalTripsConfirmed: 0,
          totalMismatched: 0,
        }
      }

      let assigned = 0
      let confirmed = 0
      let mismatched = 0

      this.trips.forEach(item => {
        assigned += item.total_assigned
        confirmed += item.total_confirmed

        if (item.total_assigned > item.total_confirmed) {
          mismatched += item.total_assigned - item.total_confirmed
        } else {
          mismatched += item.total_confirmed - item.total_assigned
        }
      })

      return {
        totalTripsAssigned: assigned,
        totalTripsConfirmed: confirmed,
        totalMismatched: mismatched,
      }
    },
    tripsData() {
      if (!this.trips.length) {
        return []
      }

      const rows = []
      const hasGroupBy = this.tripAssignmentsGroupBy && this.tripAssignmentsGroupBy.length > 0

      this.trips.forEach(item => {
        const formattedDate = dayjs.tz(item.date, "Pacific/Auckland").format("YYYY-MM-DD")

        let rowIndex

        if (hasGroupBy) {
          rowIndex = rows.findIndex(r =>
            this.tripAssignmentsGroupBy.every(group => {
              if (group === "vehicle_id") {
                return r.vehicle_id === item.vehicle_id
              }
              if (group === "date") {
                return r.date === formattedDate
              }

              return false
            }),
          )
        } else {
          // Should already be stored as one row in the DB, but just in case, merge them together
          rowIndex = rows.findIndex(r => r.vehicle_id === item.vehicle_id && r.date === formattedDate)
        }

        if (rowIndex === -1) {
          const row = {
            vehicle_id: !hasGroupBy || this.tripAssignmentsGroupBy?.includes("vehicle_id") ? item.vehicle_id : "-",
            total_assigned: item.total_assigned,
            total_confirmed: item.total_confirmed,
            total_mismatch: Math.abs(item.total_assigned - item.total_confirmed),
            date: !hasGroupBy || this.tripAssignmentsGroupBy?.includes("date") ? formattedDate : "-",
          }

          rows.push(row)
        } else {
          rows[rowIndex].total_assigned += item.total_assigned
          rows[rowIndex].total_confirmed += item.total_confirmed
          rows[rowIndex].total_mismatch += Math.abs(item.total_assigned - item.total_confirmed)
        }
      })

      return rows
    },
    tripReportsSummary() {
      if (!this.tripReports.length) {
        return {
          totalTripReports: 0,
          totalVehicles: 0,
        }
      }

      const vehicles = []

      this.tripReports.forEach(item => {
        if (!vehicles.includes(item.event_data.vehicle_id)) {
          vehicles.push(item.event_data.vehicle_id)
        }
      })

      return {
        totalTripReports: this.tripReports.length,
        totalVehicles: vehicles.length,
      }
    },
    tripReportsData() {
      if (!this.tripReports.length) {
        return []
      }

      const rows = this.tripReports.map(item => {
        item.event_data.start_datetime = dayjs
          .unix(item.event_data.start_timestamp)
          .tz("Pacific/Auckland")
          .format("YYYY-MM-DD HH:mm")

        item.event_data.end_datetime = dayjs
          .unix(item.event_data.end_timestamp)
          .tz("Pacific/Auckland")
          .format("YYYY-MM-DD HH:mm")

        return item.event_data
      })

      return rows
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true

      // Ignore time on start & end date
      const startDate = dayjs.tz(this.startDate, "Pacific/Auckland").format("YYYY-MM-DD 00:00:00")
      const endDate = dayjs.tz(this.endDate, "Pacific/Auckland").format("YYYY-MM-DD 23:59:59")

      try {
        const results = await Promise.allSettled([
          this.fetchOffRoutes(startDate, endDate),
          this.fetchNetworkUsage(startDate, endDate),
          this.fetchTripAssignments(startDate, endDate),
          this.fetchTripReports(startDate, endDate),
        ]);

        const [offRoutes, networkUsage, trips, tripReports] = results.map(result => 
          result.status === 'fulfilled' ? result.value : []
        );

        this.offRoutes = offRoutes
        this.networkUsage = networkUsage
        this.trips = trips
        this.tripReports = tripReports
      } catch (error) {
        console.error(error)
      }

      this.loading = false
    },
    async fetchOffRoutes(startDate, endDate) {
      this.offRoutesRequest.cancel("New fetch requested")
      this.offRoutesRequest = axios.CancelToken.source()

      return store.dispatch("getOffRoutesReport", {
        start_datetime: startDate,
        end_datetime: endDate,
        cancelToken: this.offRoutesRequest.token,
      })
    },
    async fetchNetworkUsage(startDate, endDate) {
      this.networkUsageRequest.cancel("New fetch requested")
      this.networkUsageRequest = axios.CancelToken.source()

      return store.dispatch("getNetworkUsageReport", {
        start_datetime: startDate,
        end_datetime: endDate,
        cancelToken: this.networkUsageRequest.token,
      })
    },
    fetchTripAssignments(startDate, endDate) {
      this.tripsRequest.cancel("New fetch requested")
      this.tripsRequest = axios.CancelToken.source()

      return store.dispatch("getTripAssignmentReport", {
        start_datetime: startDate,
        end_datetime: endDate,
        cancelToken: this.tripsRequest.token,
      })
    },
    fetchTripReports(startDate, endDate) {
      this.tripReportsRequest.cancel("New fetch requested")
      this.tripReportsRequest = axios.CancelToken.source()

      return store.dispatch("getDeviceEventsByType", {
        event_type: "trip_reports",
        start_datetime: startDate,
        end_datetime: endDate,
        cancelToken: this.tripReportsRequest.token,
      })
    },
    customSort(items, sortBy, sortDesc) {
      const sortByField = sortBy[0] ?? ""
      const sortDescending = sortDesc[0] ?? false

      items.sort((a, b) => {
        let sortA = a[sortByField]
        let sortB = b[sortByField]

        // Special handling of field sorting when required
        switch (sortByField) {
          case "upload": {
            sortA = a.upload_bytes
            sortB = b.upload_bytes

            break
          }
          case "download": {
            sortA = a.download_bytes
            sortB = b.download_bytes

            break
          }
          case "total_usage": {
            sortA = a.total_bytes
            sortB = b.total_bytes

            break
          }
          default:
            break
        }

        if (sortA > sortB) return 1
        if (sortA < sortB) return -1

        return 0
      })

      // Reverse order if header clicked again
      if (sortDescending) {
        items.reverse()
      }

      return items
    },
    getNetworkColour(totalBytes) {
      // dont apply colours unless we group by dates
      if (this.networkGroupBy && this.networkGroupBy.length) {
        return null
      }

      totalBytes = parseInt(totalBytes, 10)

      if (totalBytes > this.networkUsageAlert) {
        return "error"
      }
      if (totalBytes > this.networkUsageWarning) {
        return "warning"
      }

      return null
    },
    showNetworkColour() {
      if (this.networkGroupBy && this.networkGroupBy.length > 0) {
        return false
      }

      return true
    },
    prettyPrint(data) {
      return JSON.stringify(data, null, 4)
    },
  },
}
</script>

<style scoped></style>
